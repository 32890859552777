
export const APP = { 
  appName: "Baige Wallet",
  loadingText: "Loading..."
}

export const AUTH =  {
  loginHeader: "Welcome back",
  loginSubText: "Save and invest easily along with so many other customers with our financial tools.",

  loginIdLabel: "Username or email",
  loginIdPlaceHolder: "Please enter",

  passwordLabel: "Password",
  oldPasswordLabel: "Current Password",
  newPasswordLabel: "New Password",
  confirmPasswordLabel: "Confirm Password",
  passwordPlaceHolder: "Please enter",
  genericPlaceHolder: "Please enter",
  loginSubmitText: "Log In",

  registerActionText: "Got an account? ",
  registerLinkText: " Log In",

  registerHeader: "Get ready for an amazing Investments  and savings plans",
  registerSubText: "We have built a robust system to help you manage and increase your income greatlyWe have built a robust system to help you manage and increase your income greatly.",
  
  registerFormHeader: "Create an account",
  registerFormSubText: "Save and invest easily along with so many other customers with our financial tools.",

  firstNameLabel: "First Name",
  firstNamePlaceHolder: "Please enter",
  
  lastNameLabel: "Last Name",
  lastNamePlaceHolder: "Please enter",
  
  phoneNumberLabel: "PhoneNumber",
  phoneNumberPlaceHolder: "Please enter",
  
  emailInputLabel: "Email Address",
  emailPlaceHolder: "Please enter",
  
  registerPasswordLabel: "Password",
  registerPasswordPlaceHolder: "Please enter",
  
  registerRefferalLabel: "Referral Code (Optional)",
  registerRefferalPlaceHolder: "Please enter",

  registerSuccessHeader: "Registration Complete",
  registerSuccessSubText: "Your account has been created successfully!",
  registerSuccessLinkText: "Proceed to dashboard",
  
  loginActionText: "Don't have account? ",
  loginLinkText: "Register ",
  
  verifyOTPHeader: "Get ready for an amazing Investments  and savings plans",
  verifyOTPSubText: "We have built a robust system to help you manage and increase your income greatlyWe have built a robust system to help you manage and increase your income greatly",
  
  verifyOTPFormHeader: "Verify your identity",
  verifyOTPFormSubText1:  `We sent a code to`,
  verifyOTPFormSubText2: "Enter it here to verify your identity.",
  verifyOTPResendText: "Didn’t get the code? ",
  verifyOTPResendLink: "Resend",
  verifyOTPSubmitText: "Verify",

  forgotPasswordHeader: "Forgot your password?",
  forgotPasswordSubText: "Kindly enter your email address to have a password reset link sent to you",
  forgotPasswordButtonText: "Proceed",
  forgotPasswordLoginLinkText: "Back to login",
  forgotPasswordLoginIdText: "Email",
  forgotPasswordLoginIdPlaceholder: "Please enter",
  
  resetPasswordHeader: "Reset password",
  resetPasswordSubText: "Choose a new password",
  resetPasswordButtonText: "Update Password",
  resetPasswordLoginLinkText: "Back to login",

  resetPasswordInputText: "New Password",
  resetPasswordInputPlaceholder: "Please enter",
  resetPasswordConfirmText: "Verify Password",
  resetPasswordConfirmPlaceholder: "Please enter",

  resetSuccessHeader: "Password changed",
  resetSuccessSubText: "Your password is changed, login with your new password!",
  resetSuccessLinkText: "Proceed to log in",

  bvnInputText: "Bank Verification Number (BVN)",
  bvnInputPlaceholder: "Please enter",
  dateOfBirthInputText: "Date of Birth",
  occupationInputText: "What do you do?",
  genderInputText: "Gender",
  addressInputText: "Address",
  addressInputPlaceholder: "Please enter",
  occupationInputPlaceholder: "Please enter",
  genderInputPlaceholder: "Please enter",
  dateOfBirthInputPlaceholder: "Please enter",
  bvnSubmitButtonText: "Proceed",
  
}



export const SAVINGS = {
  savings: "Savings",
  selectPlan: "Select A Plan",
  selectPlanSubText: "Plan towards your dream home, fund your kid's education, travel the world, all in one app.",
  
  justSave: "Just Save",
  justSaveDescription: "Save money on your terms for a minimum of 3 months. ",
  
  noAccess: "No-Access",
  noAccessDescription: "Save money on your terms for a minimum of 3 months. ",

  emergency: "Emergency",
  EmergencyDescription: "Save money on your terms for a minimum of 3 months. ",

  save4aNeed:"Save4aNeed",
  save4aNeedDescription: "Save money on your terms for a minimum of 3 months.",
  
  justSavePurposeTitle: "What do you want to save for?",
  justSavePurposeSubtext: "Plan towards your dream home, fund your kid's education, travel the world, all in one app.",
  
  justSaveAmountTitle: "What do you want to save for?",
  justSaveAmountSubtext: "Plan towards your dream home, fund your kid's education, travel the world, all in one app.",
  
  enterCustom: "Let me decide",
  justSaveDefaultAmounts: [5000, 10000, 20000, 50000, 100000],
  
  justSaveIntervalTitle:"How often do you want to save?",
  justSaveIntervalSubText:"How often do you want to save?",
  
  justSaveDurationTitle:"How long do you want to save?",
  justSaveDurationSubText:"How often do you want to save?",
  
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  year: "year",
  months: "Months",
  
  step: "Step"
}

export const INVESTMENTS = {
  nairaInvestment: "Naira Fund Investment",
  nairaInvestmentDescription: "Invest and earn on your terms for a minimum of 1 year. ",
  
  dollarInvestment: "Dollar Investment",
  dollarInvestmentDescription: "Invest and earn on your terms for a minimum of 1 year. ",
  
  introProceedBtn: "Invest now",
  defaultAmounts: [5000, 10000, 20000, 50000, 100000],

  step: "Step",
  amountTitleTitle: "How much do you want to invest?",
  amountTitleSubtext: "Plan towards your dream home, fund your kid's education, travel the world, all in one app.",
  enterCustom: "Custom amount",
}


export const BUTTONS = {
  continue: "COntinue",
  update: "Update",

} 



export const FORMS = {
  firstNameEmptyError: "Please enter your first name ",
  lastNameEmptyError: "Please enter your last name ",
  passwordEmptyError: "Please enter password ",
  emailAddressEmptyError: "Please enter email address ",
  phoneNumberEmptyError: "Please enter telephone number ",
  loginIdEmptyError: "Please enter username or email ",
  otpEmptyError: "Please enter 6-digit OTP sent to you ",
  bvnEmptyError: "Please enter your bank verification number (BVN)",
  dobEmptyError: "Please select your date of birth",
}
