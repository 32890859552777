import { lazy, Suspense } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './styles/index.scss';
import { Route, Routes } from 'react-router-dom';
import AppLoader from './components/loader/loader.components';

const Home2Page = lazy(() => import('./pages/home2/Home2.pages'));
const RegisterPage = lazy(() => import('./pages/auth/register/register.pages'));
const LoginPage = lazy(() => import('./pages/auth/login/login.pages'));
const VerifyTokenPageIndex = lazy(() => import('./pages/auth/verify-token/verify-token.page'));
const ForgotPasswordPage = lazy(() => import('./pages/auth/forgot-password/forgot-password.page'));
const ResetPasswordPage = lazy(() => import('./pages/auth/reset-password/reset-password.page'));
const AuthStatusPage = lazy(() => import('./pages/auth/status/status.page'));
const DashboardPage = lazy(() => import('./pages/dashboard/dashboard.pages'));
const SavingsPageIndex = lazy(() => import('./pages/savings'));
const InvestmentPageIndex = lazy(() => import('./pages/investments'));
const SettingsPageIndex = lazy(() => import('./pages/settings/settings.page'));


function App() {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path="/"  >
          <Route index element={<Home2Page />} />
          <Route path="/app">
            <Route index element={<DashboardPage />} />
            <Route path="savings/*" element={<SavingsPageIndex />} />
            <Route path="settings/*" element={<SettingsPageIndex />} />
            <Route path="investments/*" element={<InvestmentPageIndex />} />
          </Route>
          <Route path="/auth">
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path=":action/verify-token/:type" element={<VerifyTokenPageIndex />} />
            <Route path=":action/:status" element={<AuthStatusPage />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
