import React from 'react'
import styled from 'styled-components'
import { BaigeWalletIcon, LogoIcon } from '../../assets/icons'
import { APP } from '../../lang'

const LoaderWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  .animate__animated.animate__bounce {
    animation-iteration-count: infinite;
    padding: 1rem;
  }
  
  .icons{
    display: flex;
    align-items: baseline;
  }

`

function LoaderComponent() {
  return (
    <LoaderWrapper>
      <div>
        <div className='icons  '>
          <div className=" animate__animated animate__bounce">
            <LogoIcon />
          </div>
          <div className=" animate__animated">
            <BaigeWalletIcon />
          </div>
        </div>

        <div>{APP.loadingText}</div>
      </div>

    </LoaderWrapper>

  )
}

export default LoaderComponent